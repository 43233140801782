<template>
  <q-item
    v-if="item.permissions && useCheckPermissions(item.permissions)"
    class="mx-2.5 flex flex-row items-center rounded p-0 text-[14px] tracking-wide transition-colors duration-200 hover:bg-gray-300 dark:hover:bg-fr-gray-700"
  >
    <NuxtLink
      class="flex w-full cursor-pointer flex-row items-center gap-3 rounded p-3 opacity-90"
      :to="item.name !== 'auth.login' ? item.path : undefined"
      @click.prevent="
        () => {
          if (item.name === 'auth.login') showLoginModal = true
        }
      "
    >
      <div class="flex w-full flex-row items-center gap-3">
        <q-icon v-if="item.icon" :name="item.icon" />
        <span>
          {{ item.title }}
        </span>
        <q-badge
          v-if="item?.isBeta"
          color="primary"
          text-color="white"
          label="WIP"
        />
      </div>
    </NuxtLink>
  </q-item>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import type { RouteMeta } from 'vue-router'

const authStore = useAuthStore()

const showLoginModal = computed({
  get: () => authStore.showLoginModal,
  set: (value) => (authStore.showLoginModal = value)
})

defineProps<{
  item: RouteMeta
  paddingLeft?: string
}>()
</script>
